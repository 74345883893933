import _ from 'lodash';
import moment from 'moment';

// return the next date in {day: '', month: '', year: ''} object form
const nextCalendarDate = function(date) {
  const m = moment([date['year'], date['month'] - 1, date['day']]);

  m.add(1, 'days');

  return { day: m.date(), month: m.month() + 1, year: m.year() };
}

export { nextCalendarDate as default };
