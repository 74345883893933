
import $ from 'jquery';
import _ from 'lodash';

// Cytoscape Library
// cytoscape-dagre is an add on for direct graph layout
import cytoscape from 'cytoscape';

// YOU COMMENTED THIS OUT ON 23 MAR BECAUSE OF THE Graph undefined error
// * It is likely that you need to update the cytoscape lib

import dagre from 'cytoscape-dagre';
cytoscape.use( dagre ); // register extension

import Ajv from 'ajv';

export default function (graphData) {

  if (_.isEmpty(graphData)){
    throw new Error('input data to dependency graph display is empty')
  }

  // Data Validation
  // Ensure the data conforms to the expected schema
  // Fail hard if it does not
  const InvalidDataFormat = function (message) {
    this.message = message;
    this.name = 'InvalidDataFormat';
  };


  const nodeItem = {
    type: 'object',
    properties: {
      data: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          name: { type: 'string' },
        },
        required: ['id', 'name'],
      },
    },
    required: ['data'],
  };

  const edgeItem = {
    type: 'object',
    properties: {
      data: {
        type: 'object',
        properties: {
          source: { type: 'string' },
          target: { type: 'string' },
        },
        required: ['source', 'target'],
      },
    },
    required: ['data'],
  };

  // ref: https://bugventure.github.io/jsen/json-schema
  const inputSchema = {
    title: 'Dependency Input Data',
    type: 'object',
    properties: {
      elements: {
        type: 'object',
        minimum: 1,
        required: ['nodes', 'edges'],
        properties: {
          nodes: {
            type: 'array',
            minItems: 1,
            uniqueItems: true,
            items: nodeItem,
          },
          edges: {
            type: 'array',
            minItems: 1,
            uniqueItems: true,
            items: edgeItem,
          },
        },

      },
    },
    required: ['elements'],
  };

  // validate input to function with ajv
  // raise an error if the input does not
  // have the correct form
  const ajv = new Ajv( { allErrors: true, strict: false })
  const validate = ajv.compile(inputSchema)
  const valid = validate(graphData)

  if (!valid){
    throw new Error( JSON.stringify({ name: "Invalid Dependency Graph Display Schema", schema_errors: validate.errors }) )
  }

  const cy = cytoscape({
    container: $('#cy'),
    boxSelectionEnabled: false,
    autounselectify: false,
    userZoomingEnabled: true,
    userPanningEnabled: true,

    style: cytoscape.stylesheet()
      .selector('node')
      .css({
        shape: 'roundrectangle',
        width: 'label',
        height: 'label',
        content: 'data(name)',
        color: 'black',
        padding: 10,
        'text-valign': 'center',
        'text-outline-width': 0.2,
        'background-color': 'white',
        'border-color': 'black',
        'border-width': 1.2,
      })
      .selector('edge')
      .css({
        'curve-style': 'bezier',
        'target-arrow-shape': 'triangle',
        'target-arrow-color': 'black',
        'line-color': 'black',
        width: 3,
      }),

    elements: graphData.elements,
  });

  const options = {
    name: 'dagre',
    // dagre algo options, uses default value on undefined

    nodeSep: undefined, // the separation between adjacent nodes in the same rank
    edgeSep: undefined, // the separation between adjacent edges in the same rank
    rankSep: undefined, // the separation between adjacent nodes in the same rank

    rankDir: 'TB', // 'TB' for top to bottom flow, 'LR' for left to right

    minLen: function( edge ){ return 1; },
    // number of ranks to keep between the source and target of the edge

    edgeWeight: function( edge ){ return 1; },
    // higher weight edges are generally made shorter and straighter than lower weight edges

    // general layout options
    fit: true, // whether to fit to viewport

    padding: 30, // fit padding

    spacingFactor: undefined,
    // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up

    nodeDimensionsIncludeLabels: undefined,
    // whether labels should be included in determining the space used by a node (default true)

    animate: false,
    // whether to transition the node positions

    animateFilter: function( node, i ){ return true; },
    // whether to animate specific nodes when animation is on; non-animated nodes immediately go to their final positions

    animationDuration: 500, // duration of animation in ms if enabled
    animationEasing: undefined, // easing of animation if enabled
    boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }

    transform: function( node, pos ){ return pos; },
    // a function that applies a transform to the final node position

    ready: function(){ }, // on layoutready
    stop: function(){
      cy.$('node').lock(); //lock the nodes in place so the user can't drag them
    } // on layoutstop.
  };

  cy.makeLayout(options).run();

}
