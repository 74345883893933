import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';

// core function
import isActiveDate from './active-date.js';

import nextCalendarDate from './next-calendar-date.js';


// takes a list of objects
// must the list must contain an object with boolean property active
// counts the number of 'true' values for the field 'active' in the list
const computeActiveCount = function(list) {
  return _.size(_.filter(list, o => o.active));
}

const project_days = function(start, finish){

  const startDateObject = new Date(start['year'], start['month'] - 1, start['day']);

  const finishDateObject = new Date(finish['year'], finish['month'] - 1, finish['day']);

  const days = moment(finishDateObject).diff(moment(startDateObject), 'days');

  return days

}

export default function(i){

  // establish default cal input
  const Calendar = Immutable.Record(
    {
      start:        { day: 1, month: 1, year: 2050 },
      finish:       { day: 1, month: 2, year: 2050 },
      exclude:      { weekdays: [], dates: [] },
      include:      { weekdays: [], dates: [] },
    }
  )

  if (_.isEqual(i['start'], i['finish'])) {
    throw new Error('start and finish dates are the same');
  }

  const input = new Calendar(i)

  const total_project_days = project_days(input['start'], input['finish'])

  let day = 0;
  let date = input['start'];
  let acc = _.cloneDeep([]);
  let project_day = 0;
  let is_active_date;


  while ( project_day < total_project_days ) {

    is_active_date = isActiveDate(date, input)
    project_day    = computeActiveCount(acc)

    acc.push(
      {
        day:         day,
        date:        date,
        active:      is_active_date,
        project_day: project_day,
      },
    );

    // bump the day by one
    day += 1;

    // bump the date by one day
    date = nextCalendarDate(date);
  }

  return acc;
};
