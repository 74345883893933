<template>
<div class="flex-1 flex flex-col min-h-0 bg-gray-700">
  <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
    <div class="flex items-center flex-shrink-0 px-4 justify-center">
      <img class="h-12 w-auto" :src="logo" alt="Workflow" />
    </div>
    <AppNavigation/>
  </div>

  <div class="flex flex-shrink-0 bg-gray-700 p-4">
    <a href="#" class="group block w-full flex-shrink-0">
      <div class="flex items-center text-sm font-medium text-white">
        <div data-netlify-identity-menu></div>
      </div>
    </a>
  </div>

  <div class="flex flex-shrink-0 flex-col overflow-y-auto pt-5 pb-4">
    <UserNavigation/>
  </div>

  <div class="flex-shrink-0 flex border-t border-gray-800 p-4">
    <UserProfile/>
  </div>
</div>
</template>

<script>

// we use this to discover the current route
import {useRoute} from 'vue-router'

import logo from "../assets/img/project_shark_white.svg"

import UserProfile from "./UserProfile.vue"
import AppNavigation from "./AppNavigation.vue"
import UserNavigation from "./UserNavigation.vue"

export default {
  components: {
    AppNavigation,
    UserNavigation,
    UserProfile,
  },
  setup() {
    return {
      logo: logo,
    }
  },
}
</script>
