<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-bind:id="selector"></div>
</template>

<script>

// Libraries
import _ from 'lodash';
import Promise from 'bluebird'

// Display
import display from './active-days.js'

const displaySchedules = function (i) {
  return new Promise((resolve) => {
    resolve(display(i));
  });
};

export default {
  props: ['selector','heatmap_input'],
  methods: {
    render_active_days(){
      const i = this.heatmap_input
      return Promise.resolve(i).then(displaySchedules);
    },
  },
  mounted: function() {
    return this.render_active_days()
  },
}
</script>
