<template>
  <nav class="mt-5 flex-1 px-2 space-y-1">
    <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.href === this.highlighted_path ? 'bg-gray-800 text-white' : 'text-white hover:bg-gray-600 hover:bg-opacity-75', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
      <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6 text-gray-300" aria-hidden="true" />
      {{ item.name }}
    </router-link>
  </nav>
</template>

<script>

// we use this to discover the current route
import {useRoute} from 'vue-router'

import {
  CreditCardIcon,
  UserIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Log In / Sign Up', href: '#', icon: UserIcon, current: true },
  { name: 'Plans / Payment', href: '#', icon: CreditCardIcon, current: false },
  { name: 'Support', href: '#', icon: ChatBubbleLeftRightIcon, current: false },
]

export default {
  props: ['selection_path'],
  computed: {
    highlighted_path() {
      return this.selection_path
    }
  },
  setup() {
    return {
      navigation: navigation,
    }
  },
}
</script>
