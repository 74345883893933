export default function(input){
  const definitions = {
    calendar: {
      type: 'object',
      additionalProperties: false,
      properties: {
        start:   { $ref: '#/$defs/start' },
        exclude: { $ref: '#/$defs/exclude' },
        include: { $ref: '#/$defs/include' },
      },
    },
    start: { $ref: '#/$defs/date' },
    exclude: {
      type: 'object',
      additionalProperties: false,
      properties: {
        weekdays: { $ref: '#/$defs/weekdays'},
        dates:    { $ref: '#/$defs/dates'},
      },
    },
    include: {
      type: 'object',
      additionalProperties: false,
      properties: {
        weekdays: { $ref: '#/$defs/weekdays'},
        dates:    { $ref: '#/$defs/dates'},
      },
    },
    weekdays: {
      type: 'array',
      uniqueItems: true,
      items: { type: 'string', pattern: '^monday$|^tuesday$|^wednesday$|^thursday$|^friday$|^saturday$|^sunday' },
    },
    dates: {
      type: 'array',
      items: {
        $ref: '#/$defs/date'
      },
    },
    date: {
      type: 'object',
      required: ['day', 'month', 'year'],
      properties: {
        day:   { type: 'integer', minimum: 1, maximum: 31 },
        month: { type: 'integer', minimum: 1, maximum: 12 },
        year:  { type: 'integer', minimum: 1, maximum: 2300 }
      },
    },
    overview: {
      type: 'object',
      properties: {
        name: { $ref: '#/$defs/name' },
        description: { $ref: '#/$defs/description' },
        simulations: { $ref: '#/$defs/simulations' },
        calendar: { $ref: '#/$defs/calendar' },
      },
      required: ['name', 'description'],
    },
    resources: {
      type: 'object',
      minProperties: 1,
      patternProperties: {
        '^[a-z_]*$': { $ref: '#/$defs/resource'}
      },
      additionalProperties: false,
    },
    resource: {
      type: 'object',
      properties: {
        name:        { $ref: '#/$defs/name' },
        calendar:    { $ref: '#/$defs/calendar' },
      },
      required: ['name', 'calendar'],
    },
    schedule_resources: {
      type: 'object',
      minProperties: 1,
      patternProperties: {
        '^[a-z_]*$': { $ref: '#/$defs/resource'}
      },
      additionalProperties: false,
    },
    schedule_tasks: {
      type: 'object',
      minProperties: 1,
      patternProperties: {
        '^[a-z_]*$': { $ref: '#/$defs/schedule_task'}
      },
      additionalProperties: false,
    },
    schedule_task: {
      type: 'object',
      properties: {
        name:        { $ref: '#/$defs/name' },
        depends_on:  { $ref: '#/$defs/depends_on' },
        resources:   { $ref: '#/$defs/resources_list' },
        duration:    { $ref: '#/$defs/duration' },
      },
      required: ['name', 'depends_on', 'resources', 'duration'],
    },
    schedule_calendar: {
      type: 'array',
      uniqueItems: true,
      minItems: 1,
      items: { $ref: '#/$defs/schedule_calendar_item'},
    },
    schedule_calendar_item: {
      type: 'object',
      properties: {
        day:  { type: 'integer' },
        date: { $ref: '#/$defs/date' },
      },
      required: ['day', 'date'],
    },
    schedule_resource_calendars: {
      type: 'object',
      minProperties: 1,
      patternProperties: {
        '^[a-z_]*$': { $ref: '#/$defs/schedule_resource_calendar'}
      },
      additionalProperties: false,
    },
    schedule_resource_calendar: {
      type: 'array',
      uniqueItems: true,
      items: { $ref: '#/$defs/schedule_resource_calendar_item'},
    },
    schedule_resource_calendar_item: {
      type: 'object',
      properties: {
        active: { type: 'boolean' },
        date:   { $ref: '#/$defs/date' },
      },
      required: ['date', 'active'],
    },
    schedule_task_windows: {
      type: 'object',
      minProperties: 0,
      patternProperties: {
        '^[A-Za-z_]*$': { $ref: '#/$defs/task_window' }
      },
      additionalProperties: false,
    },
    task_window: {
      type: 'object',
      properties: {
        start:  { $ref: '#/$defs/schedule_calendar_item' },
        finish: { $ref: '#/$defs/schedule_calendar_item' },
      },
      required: ['start', 'finish'],
    },
    schedule_graph: {
      type: 'object',
      properties: {
        elements: {
          type: 'object',
          minimum: 1,
          properties: {
            nodes: {
              type: 'array',
              minItems: 1,
              uniqueItems: true,
              items: { $ref: '#/$defs/node_item'},
            },
            edges: {
              type: 'array',
              minItems: 1,
              uniqueItems: true,
              items: { $ref: '#/$defs/edge_data_item'},
            },
            paths: {
              type: 'array',
              minItems: 0,
              uniqueItems: true,
              items: { $ref: '#/$defs/path_item'},
            },
          },
          required: ['nodes', 'edges', 'paths'],
        },
      },
      required: ['elements'],
    },
    node_item: {
      type: 'object',
      properties: {
        data: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            name: { type: 'string' },
            duration: {
              type: 'integer'
            },
            depends_on: {
              type: 'array'
            },
            float:  { type: 'integer' },
            start:  { type: 'integer' },
            finish: { type: 'integer' },
          },
          required: ['id', 'name', 'start', 'finish', 'float'],
        },
      },
      required: ['data'],
    },
    edge_data_item: {
      type: 'object',
      properties: {
        data: { $ref: '#/$defs/edge_item' }
      },
      required: ['data'],
    },
    edge_item: {
      type: 'object',
      properties: {
        type: 'object',
        properties: {
          source: { type: 'string' },
          target: { type: 'string' },
          weight: { type: 'integer' },
        },
        required: ['source', 'target', 'weight'],
      }
    },
    path_item: {
      type: 'object',
      properties: {
        type: 'object',
        properties: {
          edges:  {
            type: 'array',
            uniqueItems: true,
            items: { $ref: '#/$defs/edge_item' },
          },
          length: { type: 'integer' },
        },
        required: ['edges', 'length'],
      },
    },
    task_window_histogram_item: {
      type: 'object',
      properties: {
        day:   { type: 'integer' },
        date:  { $ref: '#/$defs/date' },
        tasks: {
          type: 'object',
          patternProperties: {
            '^[A-Za-z_]*$': { type: 'object' }
          },
          additionalProperties: false,
        },
      },
      required: ['day', 'date', 'tasks'],
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    simulations: {
      type: 'integer',
      minimum: 1,
      maximum: 1000,
    },
    tasks: {
      type: 'object',
      minProperties: 1,
      patternProperties: {
        '^[a-z_]*$': { $ref: '#/$defs/task'}
      },
      additionalProperties: false,
    },
    resources: {
      type: 'object',
      minProperties: 1,
      patternProperties: {
        '^[a-z_]*$': { $ref: '#/$defs/resource'}
      },
      additionalProperties: false,
    },
    resource: {
      type: 'object',
      properties: {
        name:        { $ref: '#/$defs/name' },
        description: { $ref: '#/$defs/description' },
        calendar:    { $ref: '#/$defs/calendar' },
      },
      required: ['name', 'description'],
    },
    task: {
      type: 'object',
      properties: {
        name:        { $ref: '#/$defs/name' },
        depends_on:  { $ref: '#/$defs/depends_on' },
        resources:   { $ref: '#/$defs/resources_list' },
        description: { $ref: '#/$defs/description' },
        duration:    { $ref: '#/$defs/duration' },
      },
      required: ['name', 'depends_on', 'description', 'resources'],
    },
    depends_on: {
      type: 'array',
      uniqueItems: true,
      items: { type: 'string' },
    },
    resources_list: {
      type: 'array',
      minItems: 1,
      uniqueItems: true,
      items: { type: 'string' },
    },
    duration: {
      type: 'object',
      properties: {
        estimate:     { $ref: '#/$defs/estimate' },
        distribution: { $ref: '#/$defs/distribution' },
      },
      required: ['estimate', 'distribution'],
    },
    estimate: {
      type: 'integer',
    },
    distribution: {
      type: 'object',
    },
    event_log: {
      type: 'array',
      uniqueItems: true,
      items: { $ref: '#/$defs/log_event' },
    },
    log_event: {
      type: 'object',
      properties: {
        simulation_id: { type: 'string'},
        event_number: { type: 'integer'},
        type: { type: 'string'},
        date: { $ref: '#/$defs/date' },
      },
      required: ['simulation_id', 'event_number', 'type', 'date'],
    },
  } // end schema

  return definitions;

};
