import _ from 'lodash';

// task day range takes the duration and uncertainty parameters and
// returns the range of possible days over which the task can possibly
// be worked on
//
// example returns
//  [0,1,2,3,4]
//  [0,1,2,3,4,5]
//  [0,1,2, ... ]
export default function(input){
  return _.range(0, input['duration'] + input['parameters']['late'] + 1 );
};
