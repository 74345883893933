<template>
  <DynamicDocumentationLayout>

   <template v-slot:sidebar>
     <router-view name="sidebar"/>
   </template>

   <template v-slot:documentation>
      <div class="relative bg-gray-50">
        <router-view name="documentation_navigation"/>

        <main class="lg:relative">
          <router-view name="contents"/>
        </main>
      </div>
   </template>

  </DynamicDocumentationLayout >
</template>

<script>
import DynamicDocumentationLayout from './DynamicDocumentationLayout.vue'

export default {
  components: {
    DynamicDocumentationLayout,
  },
}
</script>
