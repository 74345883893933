<template>
  <ModelingWithDistributions/>
</template>

<script>

//import ImportingProject from '../articles/ImportingProject.vue'
//import GettingStarted from '../articles/GettingStarted.vue'
import ModelingWithDistributions from '../articles/ModelingWithDistributions.vue'

export default {
  methods: { },
  components: {
    //ImportingProject,
    //GettingStarted,
    ModelingWithDistributions,
  },
}
</script>
