<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-bind:id="id" class="bg-white overflow-hidden shadow-lg rounded-lg divide-y divide-gray-200">
    <div class="px-4 py-5 sm:px-6">
      <TaskHeading
        :name="name"
        :duration_estimate="duration_estimate"
        :dependency_names="dependency_names"
        :resource_names="resource_names"
        :non_zero_estimate="non_zero_estimate"
        :is_approximate="is_approximate"
      ></TaskHeading>
    </div>
    <div class="px-4 py-5 sm:p-6">
      <!-- Content goes here -->

      <div class="grid grid-rows-1 grid-cols-12 gap-4">
        <div class="col-span-8">
          <TaskDescription
            :description="description"
          ></TaskDescription>
        </div>
        <div class="col-span-4">
          <TaskUncertainty v-if="non_zero_estimate"
            :id="id"
            :duration="duration_object"
          ></TaskUncertainty>
          <TaskUncertaintyPlaceholder v-else></TaskUncertaintyPlaceholder>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';

// Components
import TaskUncertainty from './TaskUncertainty.vue'
import TaskUncertaintyPlaceholder from './TaskUncertaintyPlaceholder.vue'
import TaskDescription from './TaskDescription.vue'
import TaskHeading from './TaskHeading.vue'

export default {
  props: ['id', 'name', 'description', 'duration_object', 'dependency_names', 'resource_names'],
  components: {
    TaskHeading,
    TaskDescription,
    TaskUncertainty,
    TaskUncertaintyPlaceholder,
  },
  computed: {
    is_approximate() {
      return this.distribution_exists && !this.is_exact
    },
    distribution_exists() {
      return _.has(this.duration_object, 'distribution')
    },
    is_exact() {
      return _.isEqual(this.duration_object['distribution']['type'], 'exact')
    },
    non_zero_estimate() {
      return !_.isEqual(this.duration_object['estimate'], 0)
    },
    duration_estimate() {
      return this.duration_object['estimate']
    },
  },
}
</script>
