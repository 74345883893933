import yaml from 'js-yaml';

export default function(data){
  try {
    yaml.safeLoad(data);
  }
  catch (e) {
    return [e.message];
  }

  return [];
}
