import _ from 'lodash';

export default function(tasks){

  const taskDependsOn = function(tasks, id){
    if( _.isEmpty( tasks[id]['depends_on'] ) ){
      return ['reserved_start'];
    } else {
      return tasks[id]['depends_on']
    }
  }

  const dependencyAssignments = function(tasks){
    return _.map(_.keys(tasks), function(id){
      return { id: id, depends_on: taskDependsOn(tasks, id) }
    })
  }

  const graphEdge = function(source, target){
    return {
      data: {
        source: source,
        target: target,
      }
    }
  }

  // identify the directed graph nodes that the finish node will depend on
  const leafNodeIds = function(edges){
    const sources = _.map(edges, function(e){
      return e['data']['source'];
    });

    // edge is a leaf edge if its target node is not found in the source nodes
    let leafEdges =  _.filter(edges, function(e){
      return !_.includes(sources,  e['data']['target'])
    })

    // return the target node ids
    return _.uniq(_.map(leafEdges, function(e){
      return e['data']['target'];
    }));
  }

  // build the connection edges that relate the leaf nodes to the finish node
  const finishEdges = function(nodeIds){
    return _.map(nodeIds, function(id){
      return {
        data: {
          source: id,
          target: 'reserved_finish',
        }
      }
    })
  }

  let edges = [];
  _.each(dependencyAssignments(tasks), (assignment) => {
    _.each(assignment['depends_on'], (dependency) => {
      edges.push( graphEdge( dependency , assignment['id'] ) )
    });
  });

  // add the edges that connect the leaf nodes to the finish
  edges = _.union(edges, finishEdges(leafNodeIds(edges)) )

  return edges;
};
