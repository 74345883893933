<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div id="cy" style="height: 600px;" data-page-id="d0998849-2559-4505-846b-3c05b53bf330" data-component="Graph"></div>
</template>

<script>
// Proxy Object Reactive Helpers
import { toRaw } from 'vue'

// Display
import dependencyGraphDisplay from './dependency-graph.js'

import _ from 'lodash';
import NullComponent from '../null/NullComponent.vue';

export default {
  props: ['cytoscape_graph'],
  components: {
    NullComponent: NullComponent,
  },
  methods: {
    render_graph(){
      return dependencyGraphDisplay(this.cytoscape_graph) ;
    },
  },
  updated: function() {
    return this.render_graph()
  },
  mounted: function() {
    return this.render_graph()
  },
}
</script>
