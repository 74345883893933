import  _ from 'lodash'

// function
import uniformDurationDistribution    from './uniform-duration-distribution.js'
import triangularDurationDistribution from './triangular-duration-distribution.js'
import normalDurationDistribution     from './normal-duration-distribution.js'
import exactDurationDistribution      from './exact-duration-distribution.js'

export default function(input){

  const type = input['distribution']['type']

  const params = {
    duration: input['estimate'], parameters: input['distribution']['parameters']
  }

  if (_.isEqual(type, 'normal')) {
    return normalDurationDistribution( params );
  }

  if (_.isEqual(type, 'uniform')) {
    return uniformDurationDistribution( params );
  }

  if (_.isEqual(type, 'triangular')) {
    return triangularDurationDistribution( params );
  }

  if (_.isEqual(type, 'exact')) {
    return exactDurationDistribution( params );
  }
}
