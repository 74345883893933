import _ from 'lodash';

import pkg from 'jstat';
const { jStat } = pkg;

import taskDayRange from './task-day-range.js';

export default function(input){

  const cdf = function(x, params) {

    const mu    = params['mean']
    const sigma = params['std']
    const a     = params['min']
    const b     = params['max']

    // cdf is not defined below the min value
    if (x < a) {
      return 0.0;
    }

    if (x > b - 1) {
      return 1.0;
    }

    // Formula Reference:
    // https://assessingpsyche.wordpress.com/2014/06/04/using-the-truncated-normal-distribution/
    // R Code Snipit:
    // (pnorm((x-mu)/sigma)-pnorm((a-mu)/sigma))/(pnorm((b-mu)/sigma)-pnorm((a-mu)/sigma))
    const numerator   =  standardNormalCDF((x - mu)/(sigma)) - standardNormalCDF((a - mu)/(sigma)) ;
    const denominator =  standardNormalCDF((b - mu)/(sigma)) - standardNormalCDF((a - mu)/(sigma)) ;
    return numerator / denominator;
  }

  const standardNormalCDF = function(x) {
    return jStat.normal.cdf( x , 0, 1 );
  }

  const stepProbability = function(s, params){
    return cdf(s, params) - cdf(s - 1, params);
  }

  // compute range of possible tasks days to iterate over
  const dayRange = taskDayRange(input)

  // stats distribution parameters
  const params = {
    mean: input['duration'],
    std:  input['parameters']['std'],
    min:  input['duration'] - input['parameters']['early'] - 1,
    max:  input['duration'] + input['parameters']['late']
  }

  return _.map( dayRange, d => (
    {
      day: d,
      probability: stepProbability(d, params)
    }
  ));

}
