<template>
  <div class="prose max-w-none" :class="classes" v-html="markdownHTML"></div>
</template>

<script>

import MarkdownIt from "markdown-it";
var md = new MarkdownIt();

export default {
  props: ["mdstring", "classes"],
  computed: {
    markdownHTML() {
      return md.render(this.mdstring);
    },
  },
};

</script>
