<template>
  <DynamicContentLayout>

   <template v-slot:sidebar>
     <router-view name="sidebar"/>
   </template>

   <template v-slot:contents>
     <router-view name="contents"/>
   </template>

  </DynamicContentLayout>
</template>

<script>
import DynamicContentLayout from './DynamicContentLayout.vue'

export default {
  components: {
    DynamicContentLayout,
  },
}
</script>
