import _ from 'lodash';

export default function(tasks){

  const graphNode = function(id, name){
    return {
      data: {
        id:   id,
        name: name,
      }
    }
  }

  const startNode = {
    data: {
      id:   'reserved_start',
      name: 'Start'
    }
  }

  const finishNode = {
    data: {
      id:   'reserved_finish',
      name: 'Finish'
    }
  }

  const taskNodes = _.map(_.keys(tasks), id => graphNode(id, tasks[id]['name']) );

  return _.union( [ startNode ] , taskNodes, [ finishNode ] )
};
