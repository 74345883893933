import _ from 'lodash';

// node.js style require is needed here since we need to run this in unit tests
import Ajv from 'ajv';

// Notes on Json Schemas
//   * using the $ref keyword works within a single given schema document
//     - if you try to refernence schema component with just JS references from code
//       there appear to be issues. If you want reuse a component JSON schema encourages
//       the $ref keyword
//     - if you try to avoid this there are work arounds to this but they have drawbacks
//   * this has a more dense representation. There is a lot of schema and if you try
//     to break it up into JS component in this file the number of lines blow up.
//

// schema definitions are shared between different data schemas
// Note that this is a FUNCTION. Need to invoke the function to get the
// schema definitions object
import definitions from '../data_schemas/schema-definitions.js';

const PROJECT_SCHEMA = {
  title: 'Project',
  type: 'object',
  properties: {
    overview:  { $ref: '#/$defs/overview' },
    resources: { $ref: '#/$defs/resources' },
    tasks:     { $ref: '#/$defs/tasks' },
  },
  required: [
    'overview',
    'tasks',
    'resources',
  ],
  additionalProperties: false,
  $defs: definitions(),
};

export default function(input){

  // ensure the ajv validor returns all errors
  // this is important for our user error experience since
  // we need the user to be able to correct as many edit related
  // errors as possible in one shot.
  const ajv = new Ajv( { allErrors: true })

  const validate = ajv.compile(PROJECT_SCHEMA)

  const valid = validate(input)

  if(valid){
    // valid is js null, so we need to return an empty array instead
    return []
  } else {
    return _.cloneDeep(validate.errors)
  }
}
