<!-- This example requires Tailwind CSS v2.0+ -->
<template>
 <div v-if="graph_present" id="dependency-graph-canvas" class="bg-white overflow-hidden shadow-lg rounded-lg divide-y divide-gray-200 p-4" data-component="DependencyGraph">
   <Graph
     :cytoscape_graph='graph'
   ></Graph>
 </div>
  <ul v-else role="list" class="space-y-3">
    <li>
      <NullComponent
        component_id='dependencies'
        heading='Missing Dependencies'
      ></NullComponent>
    </li>
  </ul>
</template>

<script>

// Proxy Object Reactive Helpers
import { toRaw } from 'vue'

import _ from 'lodash';
import Graph from './Graph.vue';
import NullComponent from '../null/NullComponent.vue';

export default {
  components: {
    Graph: Graph,
    NullComponent: NullComponent,
  },
  computed: {
    graph_present() {
      return !_.isEmpty(this.$store.getters.dependency_graph)
    },
    graph(){
      // ensure we pass the raw js object down
      // and not the Vue JS proxy object
      return toRaw(this.$store.getters.dependency_graph)
    }
  },
}
</script>
