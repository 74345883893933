<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul v-if="resources_present" role="list" class="space-y-3" data-component="Resources">
    <li v-for="id in resource_ids()">
      <Resource
        :id="resource_id(id)"
        :name="resource_name(id)"
        :description="resource_description(id)"
        :calendar="display_calendar_inputs(id)"
      ></Resource>
    </li>
  </ul>
  <ul v-else role="list" class="space-y-3">
    <li>
      <NullComponent
        component_id='resources'
        heading='Missing Resources'
      ></NullComponent>
    </li>
  </ul>
</template>

<script>

// Proxy Object Reactive Helpers
import { toRaw } from 'vue'

import _ from 'lodash'
import Resource from './Resource.vue'
import NullComponent from '../null/NullComponent.vue';

export default {
  components: {
    Resource: Resource,
    NullComponent: NullComponent,
  },
  computed: {
    resources_present() {
      return !_.isEmpty(this.$store.getters.resources)
    },
    resources() {
      return toRaw(this.$store.getters.resources)
    },
  },
  methods: {
    resource_ids() {
      return _.keys(this.resources)
    },
    resource_id(id) {
      return id
    },
    resource_name(id) {
      return this.resources[id]['name']
    },
    resource_description(id) {
      return this.resources[id]['description']
    },
    resource_calendar(id) {
      return this.resources[id]['calendar']
    },
    project_span() {
      return toRaw(this.$store.getters.estimated_project_span)
    },
    display_calendar_inputs(id) {
      // get the user configured resource calendar
      const individual_resource_calendar = _.cloneDeep(this.resource_calendar(id))

      // merge in the finish date from the estimated project span
      const project_finish_date = this.project_span(id)['finish']
      const display_resource_calendar = _.extend({ finish: project_finish_date }, individual_resource_calendar )

      return display_resource_calendar;
    }
  }
}
</script>
