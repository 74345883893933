<template>
  <Markdown :mdstring="md" />
</template>

<script>

import Markdown from '../markdown/Markdown.vue'

export default {
  props: ['description'],
  methods: {
  },
  components: {
    Markdown,
  },
  computed: {
    md() {
      return this.description
    }
  },
}
</script>
