import { createApp } from 'vue'
import App from './components/App.vue'
import './index.css'
import router from "./router/index"
import store from "./js/store/vuex-store"
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// create app instance
const app = createApp(App)

Sentry.init({
  app,
  environment: 'production',
  dsn: "https://05c5555d2855423ca1247c9c5bbf966d@o1289449.ingest.sentry.io/6507924",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  //release: "project-shark@2.3.12",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app.project-shark\.com\/api/, "*-project-shark.netlify.app", "https://app.netlify.com/*"],

  // // Profile X% of the transactions. This value is relative to tracesSampleRate
  profilesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router).use(store)
app.mount('#app')
