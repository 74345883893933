<template>
  <div class="p-4">
    <ul v-if="errors_present" role="list" class="space-y-3">
      <li v-for="e in yaml_errors">
        <ProjectSharkError
          type="YAML"
          notification="NO"
          :raw_error_message="e"
          :description="yaml_error_md()"
        ></ProjectSharkError>
      </li>

      <li v-for="e in schema_errors">
        <ProjectSharkError
          type="Project Schema"
          notification=false
          :raw_error_message="e"
          :description="schema_error_md(e)"
        ></ProjectSharkError>
      </li>

      <li v-for="e in trail_limit_errors">
        <ProjectSharkError
          type="Trial Limit"
          notification="YES"
          raw_error_message="-"
          :description="trial_limit_md()"
        ></ProjectSharkError>
      </li>

      <li v-for="e in task_dependency_errors">
        <ProjectSharkError
          type="Task Dependency"
          notification=false
          :raw_error_message="e"
          :description="task_dependency_error_md()"
        ></ProjectSharkError>
      </li>
    </ul>
    <ul v-else role="list" class="space-y-3">
      <li>
        <NullComponent
          component_id='errors'
          heading='No Project Errors'
        ></NullComponent>
      </li>
    </ul>
  </div>
</template>

<script>

/////////////////////////////////////
// LIBRARY IMPORTS
/////////////////////////////////////
import commonmark from 'commonmark'
import _ from 'lodash';
import MarkdownIt from 'markdown-it'

/////////////////////////////////////
// COMPONENT IMPORTS
/////////////////////////////////////
import ProjectSharkError from './ProjectSharkError.vue'
import NullComponent from '../null/NullComponent.vue'

/////////////////////////////////////
// MARKDOWN FILE IMPORTS
/////////////////////////////////////
import TRIAL_LIMIT_MESSAGE from './messages/trial_project_error.md?raw'
import YAML_ERROR_MESSAGE from './messages/invalid_yaml.md?raw'
import TASK_DEPENDENCY_MESSAGE from './messages/depends_on_validation_error.md?raw'
import GENERAL_SCHEMA_ERROR_MESSAGE from './messages/general_schema_validation_error.md?raw'
import missing_section from './messages/missing_section.md?raw'
import missing_overview_information from './messages/missing_overview_information.md?raw'
import invalid_overview_information from './messages/invalid_overview_information.md?raw'
import no_project_tasks_listed from './messages/no_project_tasks_listed.md?raw'
import missing_task_information from './messages/missing_task_information.md?raw'
import invalid_task_dependencies from './messages/invalid_task_dependencies.md?raw'
import invalid_task_duration from './messages/invalid_task_duration.md?raw'
import invalid_task_resources from './messages/invalid_task_resources.md?raw'
import invalid_resource_specification from './messages/invalid_resource_specification.md?raw'
import invalid_identifier from './messages/invalid_identifier.md?raw'
import invalid_weekdays from './messages/invalid_weekdays.md?raw'

/////////////////////////////////////
// SCHEMA ERROR DISCOVERY
/////////////////////////////////////

// this takes the raw jsen schema error and findes the appropriate
// custom message string
const find_schema_error_message = function(e){
  if( _.isUndefined( matchErrObj(e) ) ) {
    return GENERAL_SCHEMA_ERROR_MESSAGE;
  }

  return matchErrObj(e)['message']
}

const matchErrObj = function(e){
  return _.find(ANTICIPATED_ERRORS, function(ae) {
    return pathMatches(e,ae) && keywordMatches(e,ae);
  });
}

const pathMatches = function(actual, anticipated){
  const reg_exp = new RegExp(anticipated['path'])
  return reg_exp.test(actual['instancePath'])
}

const keywordMatches = function(actual, anticipated){
  return _.isEqual( actual['keyword'], anticipated['keyword'] )
}

/////////////////////////////////////
// ANTICIPATED SCHEMA ERRORS
/////////////////////////////////////
const ANTICIPATED_ERRORS = [{
        path: '^$',
        keyword: 'required',
        message: missing_section,
    },
    {
        path: '^\/overview$',
        keyword: 'required',
        message: missing_overview_information,
    },
    {
        path: '^\/overview\/description$',
        keyword: 'required',
        message: missing_overview_information,
    },
    {
        path: '^\/overview\/name$',
        keyword: 'type',
        message: invalid_overview_information,
    },
    {
        path: '^\/overview\/description$',
        keyword: 'type',
        message: invalid_overview_information,
    },
    {
        path: '^\/tasks$',
        keyword: 'additionalProperties',
        message: invalid_identifier,
    },
    {
        path: '^\/tasks$',
        keyword: 'minProperties',
        message: no_project_tasks_listed,
    },
    {
        path: '^\/tasks(.+)\/name$',
        keyword: 'required',
        message: missing_task_information,
    },
    {
        path: '^\/tasks(.+)\/duration$',
        keyword: 'required',
        message: missing_task_information,
    },
    {
        path: '^\/tasks(.+)\/depends_on',
        keyword: 'type',
        message: invalid_task_dependencies,
    },
    {
        path: '^\/tasks(.+)\/estimate$',
        keyword: 'required',
        message: invalid_task_duration,
    },
    {
        path: '^\/tasks(.+)\/distribution$',
        keyword: 'required',
        message: invalid_task_duration,
    },
    {
        path: '^\/tasks(.+)\/resources',
        keyword: 'required',
        message: invalid_task_resources,
    },
    {
        path: '^\/tasks(.+)\/resources',
        keyword: 'minItems',
        message: invalid_task_resources,
    },
    {
        path: '^\/tasks(.+)\/resources',
        keyword: 'type',
        message: invalid_task_resources,
    },
    {
        path: '^\/resources',
        keyword: 'required',
        message: invalid_resource_specification,
    },
    {
        path: '^\/resources',
        keyword: 'additionalProperties',
        message: invalid_identifier,
    },
    {
        path: '^\/resources(.+)\/name',
        keyword: 'required',
        message: invalid_resource_specification,
    },
    {
        path: '^\/resources(.+)\/description',
        keyword: 'required',
        message: invalid_resource_specification,
    },
    {
        path: '^\/resources(.+)\/name',
        keyword: 'type',
        message: invalid_resource_specification,
    },
    {
        path: '^\/resources(.+)\/description',
        keyword: 'type',
        message: invalid_resource_specification,
    },
    {
        path: '^\/resources(.+)\/calendar(.+)\/weekdays(.+)',
        keyword: 'pattern',
        message: invalid_weekdays,
    },
];

/////////////////////////////////////
// BUILD ERROR COMPONENT
/////////////////////////////////////
export default {
  components: {
    ProjectSharkError: ProjectSharkError,
    NullComponent: NullComponent,
  },
  methods: {
    trial_limit_md() {
      return TRIAL_LIMIT_MESSAGE
    },
    yaml_error_md() {
      return YAML_ERROR_MESSAGE
    },
    task_dependency_error_md () {
      return TASK_DEPENDENCY_MESSAGE
    },
    schema_error_md(e) {
      return find_schema_error_message(e)
    },
  },
  computed: {
    errors_present() {

      // get the errors object from the store
      const errors = this.$store.getters.errors

      // true if all arrays are empty, false otherwise
      const allEmpty = _.every(errors, _.isEmpty);

      return !allEmpty
    },
    yaml_errors() {
      return this.$store.getters.errors['yaml']
    },
    schema_errors() {
      return this.$store.getters.errors['schema']
    },
    task_dependency_errors() {
      return this.$store.getters.errors['depends_on']
    },
    trail_limit_errors() {
      return this.$store.getters.errors['trial_project_size']
    }
  }
}
</script>
