import _ from 'lodash';
import Ajv from 'ajv';

// functions
import graphNodes from './graph-nodes.js';

import graphEdges from './graph-edges.js';

export default function(tasks){

  let schema = {
    type: 'object',
    minProperties: 1,
    patternProperties: {
      '^[a-z_]*$': { $ref: '#/$defs/task'}
    },
    additionalProperties: false,
    $defs:{
      task: {
        type: 'object',
        required: ['name', 'depends_on'],
        properties: {
          name:       {type: 'string'},
          depends_on: {type: 'array', items: {type: 'string'} }
        },
      },
    }
  }

  // validate input to function with ajv
  // raise an error if the input does not
  // have the correct form
  const ajv = new Ajv( { allErrors: true, strict: false })
  const validate = ajv.compile(schema)
  const valid = validate(tasks)

  if (!valid){
    throw new Error( JSON.stringify({ name: "Invalid Dependency Graph Schema", schema_errors: validate.errors }) )
  }

  const nodes = graphNodes(tasks)
  const edges = graphEdges(tasks)

  return {
    elements: {
      nodes: nodes,
      edges: edges,
    }
  }
}
