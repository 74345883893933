import _ from 'lodash';
import moment from 'moment';

// map days to iso weekday number
const days = {
  monday:    1,
  tuesday:   2,
  wednesday: 3,
  thursday:  4,
  friday:    5,
  saturday:  6,
  sunday:    7,
};

// lookup list of iso day numbers
const isoDayNumbers = function(weekdays){
 return _.map( weekdays, d => days[d] );
}

// returns weekday string. 'monday', 'wednesday' ect.
const weekday = function(date){
  // NOTE: In moment js months are zero indexed. (WTF!)
  return moment([date['year'], date['month'] - 1, date['day']]).isoWeekday();
}

const inWeekdays = function(date, weekdays){
  return _.includes( isoDayNumbers( weekdays ), weekday(date) );
}

const inDates = function(date, dates){
  return !_.isEmpty(_.filter(dates, d => _.isEqual(d, date)))
}

const isIncluded = function(date, description){
  return inWeekdays(date, description['weekdays'] ) || inDates(date, description['dates'])
}

const isExcluded = function(date, description) {
  return inWeekdays(date, description['weekdays'] ) || inDates(date, description['dates'])
}

const isActiveDate = function(date, desc) {
  // Business Rules:
  // * included by default
  // * in order to inactivate a day you need to exclude
  //   it AND not explicately include it back in

  // excluded and included
  if (isExcluded(date, desc['exclude']) && isIncluded(date, desc['include'])) {
    return true;
  }

  // excluded but not included
  if (isExcluded(date, desc['exclude']) && !isIncluded(date, desc['include'])) {
    return false;
  }

  // not excluded but included
  if (!isExcluded(date, desc['exclude']) && isIncluded(date, desc['include'])) {
    return true;
  }

  // not excluded and not included
  if (!isExcluded(date, desc['exclude']) && !isIncluded(date, desc['include'])) {
    return true;
  }

}

export { isActiveDate as default, isIncluded as isIncluded, isExcluded as isExcluded }
