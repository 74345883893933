<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <button @click="select_project" class="w-full flex items-center justify-between p-6 space-x-6">
    <div class="truncate">
      <div class="flex items-center space-x-3">
        <h3 class="text-gray-900 text-sm font-medium truncate">{{ name }}</h3>
      </div>

      <div class="flex items-center space-x-3">
        <p class="mt-1 text-gray-500 text-sm truncate">{{ description }}</p>
      </div>
    </div>
  </button>
</template>

<script>
import { DocumentArrowDownIcon, CodeBracketIcon } from  '@heroicons/vue/24/solid'

export default {
  components: {
    DocumentArrowDownIcon,
    CodeBracketIcon,
  },
  props: ['id', 'data_target', 'name', 'description', 'role', 'telephone', 'email', 'yaml'],
  methods: {
    select_project() {

      const store = this.$store

      store.dispatch({
        type: 'importProject',
        project: this.yaml,
      })

      return {}
    },
  },
  setup() {
    return { }
  },
}
</script>
