<template>
<nav class="mt-5 flex-1 px-2 space-y-1" data-component='AppNavigation'>
  <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.href === this.highlighted_path ? 'bg-gray-800 text-white' : 'text-white hover:bg-gray-600 hover:bg-opacity-75', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
    <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6 text-gray-300" aria-hidden="true" />
    {{ item.name }}
  </router-link>
</nav>
</template>

<script>

// we use this to discover the current route
import {useRoute} from 'vue-router'

import {
  CalendarIcon,
  ClipboardDocumentListIcon,
  ChartBarIcon,
  FolderIcon,
  PuzzlePieceIcon,
  HomeIcon,
  BookOpenIcon,
  ShieldExclamationIcon,
  UsersIcon,
} from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Projects', href: '/projects', icon: HomeIcon },
  { name: 'Tasks', href: '/tasks', icon: ClipboardDocumentListIcon },
  { name: 'Dependencies', href: '/dependencies', icon: PuzzlePieceIcon },
  { name: 'Resources', href: '/resources', icon: UsersIcon },
  { name: 'Schedule', href: '/schedule', icon: CalendarIcon },
  { name: 'Documentation', href: '/documentation', icon: BookOpenIcon },
  { name: 'Errors', href: '/errors', icon: ShieldExclamationIcon },
]

export default {
  props: ['selection_path'],
  computed: {
    highlighted_path() {
      return this.selection_path
    }
  },
  setup() {
    return {
      navigation: navigation,
    }
  },
}
</script>
