<template>
  <Hero></Hero>
  <Features></Features>
  <Pricing></Pricing>
  <Footer></Footer>
</template>

<script>
import Hero from './Hero.vue'
import Features from './Features.vue'
import Pricing from './Pricing.vue'
import Footer from './Footer.vue'

export default {
  components: {
    Hero,
    Pricing,
    Features,
    Footer,
  },
}
</script>
