<template>
  <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
    <div class="px-4 py-5 sm:px-6">
      <div>
        <p>
          {{ name }}
        </p>
      </div>
      <div>
        <p class="italic ...">
          {{ description }}
        </p>
      </div>
    </div>
    <div v-if="calendar_present" class="px-4 py-5 sm:p-6">
      <Calendar
        :id="id"
        :start="calendar.start"
        :finish="calendar.finish"
        :include="calendar.include"
        :exclude="calendar.exclude"
      ></Calendar>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Calendar from '../calendar/Calendar.vue';

export default {
  props: ['id', 'name', 'description', 'calendar'],
  components: {
    Calendar: Calendar,
  },
  methods: { },
  computed: {
    calendar_present() {
      return _.isObject(this.calendar) && _.keys(this.calendar).length > 0
    },
  },
}
</script>
