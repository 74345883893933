<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul v-if="schedules_present" role="list" class="space-y-3" data-component="TaskSchedules">
    <li v-for="id in task_ids()">
      <TaskSchedule
        :id="id"
        :name="task_name(id)"
        :calendar_span="calendar_span(id)"
        :aggregated_schedule="aggregated_schedule(id)"
        :simulated_schedules="simulated_schedules(id)"
      ></TaskSchedule>
    </li>
  </ul>
  <ul v-else role="list" class="space-y-3">
    <li>
      <NullComponent
        component_id='schedule'
        heading='Missing Task Schedules'
      ></NullComponent>
    </li>
  </ul>
</template>

<script>

// Display
import _ from 'lodash';
import TaskSchedule from './TaskSchedule.vue'
import NullComponent from '../null/NullComponent.vue';

export default {
  components: {
    TaskSchedule: TaskSchedule,
    NullComponent: NullComponent,
  },
  methods: {
    task(id) {
      return this.tasks[id]
    },
    task_name(id) {
      return this.tasks[id]['name']
    },
    task_ids() {
      return _.keys(this.task_schedules)
    },
    aggregated_schedule(id) {
      return this.task_schedules[id]['aggregated_schedule']
    },
    simulated_schedules(id) {
      return this.task_schedules[id]['simulated_schedules']
    },
    calendar_span(id) {
      return this.task_schedules[id]['calendar_span']
    },
  },
  computed: {
    schedules_present() {
      return !_.isEmpty(this.$store.getters.task_schedules)
    },
    tasks() {
      return this.$store.getters.tasks
    },
    task_schedules() {
      return this.$store.getters.task_schedules
    },
  },
}
</script>
