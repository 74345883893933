<template>
  <div class="grid grid-rows-2 gap-1">
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-4">
        <h3 class="text-gray-900 text-lg font-medium">{{ name }}</h3>
      </div>
      <div v-if="non_zero_estimate"  class="col-span-2 flex justify-center">
        <h3 class="text-gray-900 text-lg font-medium">
          <span v-if="is_approximate"> ~ </span> {{ duration_estimate }} <span v-if="is_many_days"> days </span> <span v-if="is_single_day"> day </span>
        </h3>
      </div>
    </div>

    <div v-if="has_resources" class="flex flex-row gap-4">
      <div>
        <p class="mt-1 text-gray-500 text-sm truncate">requires:</p>
      </div>
      <div>
        <span v-for="resource_name in resource_names " class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
          {{resource_name}}
        </span>
      </div>
    </div>

    <div v-if="has_dependencies" class="flex flex-row gap-4">
      <div>
        <p class="mt-1 text-gray-500 text-sm truncate">depends on:</p>
      </div>
      <div>
        <span v-for="dependency_name in dependency_names" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
          {{dependency_name}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';

export default {
  props: ['name', 'duration_estimate', 'dependency_names', 'resource_names', 'is_approximate', 'non_zero_estimate'],
  computed: {
    has_dependencies() {
      return !_.isEmpty(this.dependency_names)
    },
    has_resources() {
      return !_.isEmpty(this.resource_names)
    },
    is_single_day() {
      return _.isEqual(this.duration_estimate, 1)
    },
    is_many_days() {
      return !_.isEqual(this.duration_estimate, 1)
    },
  },
  setup() {
    return { }
  },
}
</script>
