import _ from 'lodash';

export default function(input){

  const stepProbability = function(s, params){
    if (_.isEqual( s, params['duration']) ){
      return 1.0;
    }else{
      return 0.0;
    }
  }

  // tweek range beyond input duration
  const buffer = 3

  // compute range of possible tasks days to iterate over
  const dayRange = _.range(0, input['duration'] + buffer)

  // stats distribution parameters
  const params = {
    duration: input['duration'],
  }

  return _.map( dayRange, d => (
    {
      day: d,
      probability: stepProbability(d, params)
    }
  ));

}
