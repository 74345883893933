// Libs
import _ from 'lodash';
import moment from 'moment';

//Cal Heatmap JS
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';

// Cal Heatmap CSS
import 'cal-heatmap/cal-heatmap.css';

export default function (calendar, selector) {

  const chartData = _.map(calendar, (obj) => {

    // active or not mapped to numeric
    //
    // docs - understand the d3 discrete color scheme
    // https://cal-heatmap.com/docs/options/scale
    // https://d3js.org/d3-scale-chromatic/sequential#schemeGreys
    const code = {
      true: 4,
      false: 0,
    };

    const day = obj['date']['day'];
    const month = obj['date']['month'];
    const year = obj['date']['year'];
    const active = obj['active'];

    const date_string = new Date(year, month - 1, day).toDateString()
    const value = code[active];

    return { 'date': date_string, 'value': value };
  });

  const startDate = _.first(calendar)['date'];

  const startDateObject = new Date(startDate['year'], startDate['month'] - 1, startDate['day']);

  const finishDate = _.last(calendar)['date'];

  const finishDateObject = new Date(finishDate['year'], finishDate['month'] - 1, finishDate['day']);

  // calculate the total number of months to display
  const months = _.ceil(moment(finishDateObject).diff(moment(startDateObject), 'months', true));

  const cal = new CalHeatmap();

  cal.paint(
    {
      data: {
        source: chartData,
        type: 'json',
        x: 'date',
        y: d => +d['value'],
        groupY: 'max',
      },
      date: { start: startDateObject },
      range: months,
      scale: {
        color: {
          type: 'quantize',
          scheme: 'Greys',
          domain: [0, 8],
        },
      },
      domain: {
        type: 'month',
      },
      subDomain: { type: 'day', radius: 2 },
      itemSelector: selector,
    },
    [
      [
        Tooltip,
        {
          text: function (date, value, dayjsDate) {
            return (
              (value ? 'Active' : 'Not Active') +
              ' on ' +
              dayjsDate.format('LL')
            );
          },
        },
      ],
      [
        CalendarLabel,
        {
          width: 30,
          textAlign: 'start',
          text: () => dayjs.weekdaysShort().map((d, i) => (i % 2 == 0 ? '' : d)),
        },
      ],
    ]
  );
};
