<template>

    <Popover class="relative bg-white shadow">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
              <span class="sr-only">Open menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden md:flex space-x-10">

            <Popover class="relative" v-slot="{ open }">
              <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500']">
                <span>Tutorials</span>
                <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel class="absolute -ml-4 mt-3 transform z-50 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <router-link v-for="item in tutorials" :key="item.name" :to="item.href" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-gray-600" aria-hidden="true" />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ item.description }}
                          </p>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>


            <Popover class="relative" v-slot="{ open }">
              <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500']">
                <span>Concepts</span>
                <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel class="absolute left-1/2 z-50 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <router-link v-for="item in concepts" :key="item.name" :to="item.href" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-gray-600" aria-hidden="true" />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ item.description }}
                          </p>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

          </PopoverGroup>
          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          </div>
        </div>
      </div>

      <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
        <PopoverPanel focus class="absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right md:hidden">
          <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <div class="-mr-2">
                  <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <nav class="grid gap-y-8">
                  <router-link v-for="item in features" :key="item.name" :to="item.href" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                    <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-gray-600" aria-hidden="true" />
                    <span class="ml-3 text-base font-medium text-gray-900">
                      {{ item.name }}
                    </span>
                  </router-link>
                </nav>
              </div>
            </div>
            <div class="py-6 px-5 space-y-6">
              <div class="grid grid-cols-2 gap-y-4 gap-x-8">
                <a v-for="item in concepts" :key="item.name" :href="item.href" class="text-base font-medium text-gray-900 hover:text-gray-700">
                  {{ item.name }}
                </a>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>

</template>

<script>

import DocumentationContent from './DocumentationContent.vue'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  EllipsisHorizontalIcon,
  CpuChipIcon,
  ShareIcon,
  LightBulbIcon,
  DocumentArrowUpIcon,
  VariableIcon,
  DocumentTextIcon,
  CalendarDaysIcon,
  WrenchScrewdriverIcon,
  Bars3Icon,
  XMarkIcon,
} from  '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'

const tutorials = [
  {
    name: 'Getting Started',
    href: '/documentation/getting-started',
    description: "Let's get you up and running quickly!",
    icon: LightBulbIcon,
  },
  {
    name: 'Importing a Project',
    href: '/documentation/importing-project',
    description: "Walkthrough on importing and running your own projects",
    icon: DocumentArrowUpIcon,
  },
  {
    name: 'Modeling Uncertainty with Distributions',
    href: '/documentation/modeling-uncertainty-distributions',
    description: "Capture model project uncertainty accurately and de-risk your work.",
    icon: VariableIcon,
  },
  {
    name: 'Describing Tasks with Commonmark',
    href: '/documentation/describing-tasks',
    description: 'A strongly defined, highly compatible specification of Markdown.',
    icon: DocumentTextIcon,
  },
]

const concepts = [
  {
    name: 'Resources and Dependencies',
    description: 'Learn how constraints on people, shared services, and other tasks effect your project estimates',
    href: '/documentation/resources-dependencies',
    icon: ShareIcon,
  },
  {
    name: 'Interpreting the Project Schedule',
    description: 'Visualizing the simulated project schedule and interpreting it correctly',
    href: '/documentation/interpreting-project-schedule',
    icon: CalendarDaysIcon,
  },
  {
    name: 'Modeling Real World Scenarios',
    description: 'Using distributions across a range of real world scenarios',
    href: '/documentation/modeling-real-world-scenarios',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Simulation Modeling and Algorithms',
    description: 'Understanding the project managment algorithms and design choices',
    href: '/documentation/simulation-modeling-algorithms',
    icon: CpuChipIcon,
  },
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
    DocumentationContent,
  },
  setup() {
    return {
      tutorials,
      concepts,
    }
  },
}
</script>
