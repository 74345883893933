import _ from 'lodash';
import task_cycles from './task-cycles.js';

const selfReferencing = function(tasks){
  let corrupt = []; let ids = _.keys(tasks);
  _.each(ids, function(id){
    if (_.includes(tasks[id]['depends_on'], id)) {
      corrupt.push(id)
    }
  })

  return corrupt;
}

const nonExistents = function(tasks){
  let corrupt = []; let ids = _.keys(tasks);
  _.each(ids, function(id){
    // check each id in the depends_on array
    _.each(tasks[id]['depends_on'], function(did){
      if (!_.includes(ids, did)) {
        corrupt.push(id)
      }
    })
  })

  return corrupt;
}

export default function(input){

  let errors = []
  const tasks = input['tasks']

  if ( !_.isEmpty( selfReferencing(tasks) ) ) {
    errors.push({
      message: 'task depends on self',
      task_ids: selfReferencing(tasks)
    })
  }

  if ( !_.isEmpty( nonExistents(tasks) ) ) {
    errors.push({
      message: 'task depends on missing task',
      task_ids: nonExistents(tasks)
    })
  }

  // task_cycles returns an array of arrays
  // each sub array is cycle of task ids
  // [[a,b,c],[d,e]]
  const cycles = task_cycles(input['tasks'])

  if ( !_.isEmpty( cycles ) ) {
    _.each(cycles, function(cycle){
      errors.push({
        message: 'task cycle found',
        task_ids: cycle
      })
    })
  }

  return errors;
}
