<!-- This example requires Tailwind CSS v2.0+ -->
<template>

 <div v-if="is_notification" class="bg-white rounded-md shadow-lg p-4 border-solid border-2 border-gray-600 ...">
    <div class="ml-3">
      <div class="flex justify-end ...">
        <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-gray-100 bg-gray-600 rounded-full">{{type}}</span>
      </div>
      <div class="prose prose-gray prose-lg prose-pre:bg-white prose-pre:shadow-lg prose-pre:text-gray-900">
        <Markdown :mdstring="md" />
      </div>
    </div>
  </div>

  <div v-else class="bg-white rounded-md shadow-lg p-4 border-solid border-2 border-red-600 ...">
    <div class="ml-3">
      <div class="flex justify-end ...">
        <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">{{type}}</span>
      </div>
      <div class="bg-white overflow-hidden shadow-lg rounded-lg p-4">
        <pre><code> {{ raw_error_message }} </code></pre>
      </div>
      <div class="prose prose-gray prose-lg prose-pre:bg-white prose-pre:shadow-lg prose-pre:text-gray-900">
        <Markdown :mdstring="md" />
      </div>
    </div>
  </div>


</template>

<script>

import Markdown from '../markdown/Markdown.vue'
export default {
  props: ['type', 'notification', 'raw_error_message', 'description'],
  components: {
    Markdown,
  },
  computed: {
    is_notification() {
      const result = this.notification == 'YES'
      return result;
    },
    md() {
      return this.description
    },
  },
}
</script>
