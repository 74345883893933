import _ from 'lodash';

import pkg from 'jstat';
const { jStat } = pkg;

import taskDayRange from './task-day-range.js';

export default function(input){

  const cdf = function(x, params) {
    return jStat.triangular.cdf(x, params['min'], params['max'], params['mid']);
  }

  const stepProbability = function(s, params){
    return cdf(s, params) - cdf(s - 1, params);
  }

  // compute range of possible tasks days to iterate over
  const dayRange = taskDayRange(input)

  // stats distribution parameters
  const params = {
    min: input['duration'] - input['parameters']['early'] - 1,
    max: input['duration'] + input['parameters']['late'],
    mid: input['duration'],
  }

  return _.map( dayRange, d => (
    {
      day: d,
      probability: stepProbability(d, params)
    }
  ));

}
