import Chart from 'chart.js/auto';
import _ from 'lodash';
import $ from 'jquery';
import jsen from 'jsen';

export default function (taskId, data) {

  // Data Validation
  // Ensure the data conforms to the expected schema
  // Fail hard if it does not

  const InvalidDataFormat = function (message) {
    this.message = message;
    this.name = 'InvalidDataFormat';
  };

  const dayProbabilitySchema = {
    type: 'object',
    required: ['day', 'probability'],
    properties: {
      day: {
        type: 'integer',
        minimum: 0,
      },
      probability: {
        type: 'number',
        minimum: 0.0,
        maximum: 1.0,
      },
    },
  };

  // ref: https://bugventure.github.io/jsen/json-schema
  const inputSchema = {
    title: 'Task Uncertainty Input Data',
    type: 'object',
    minProperties: 1,
    properties: {
      duration: {
        type: 'integer',
        minimum: 0,
      },
      histogram: {
        type: 'array',
        minItems: 1,
        uniqueItems: true,
        items: dayProbabilitySchema,
      },
    },
    required: ['duration', 'histogram'],
  };

  const validationErrors = function (input, schema) {
    const validate = jsen(schema); validate(input);
    const e = validate.errors;
    return e;
  };

  // input data validation
  const errors = validationErrors(data, inputSchema);

  if (!_.isEmpty(errors)) {
    throw new InvalidDataFormat('data structure passed to the display fails schema validation');
  }

  // build dom selectors for display components
  const selector = `#${taskId} .histogram-display`;

  // derived display parameters

  // histgram data
  const hist = data.histogram;

  // build histogram data arrays
  const days = _.map(hist, obj => obj['day']);
  const probabilities = _.map(hist, obj => obj['probability']);

  // largest probability value
  const maxDisplayProbability = _.max(probabilities);

  // find canvas element in the dom
  const canvasEl = $(selector)[0];

  // histogram bar color
  const barColor = 'rgba(255, 255, 255, 0.2)';

  // histogram border color
  const barBorderColor = 'rgba(0, 0, 0, 1)';

  // colors
  const lightGrey = 'rgba(221, 221, 221, 0.3)';
  const darkBlack = 'rgba(119, 119, 119, 1.0)';

  // experiment with line array
  const lineHeight = 0.25 * maxDisplayProbability;

  // last x axis day tickmark
  const duration = data.duration + 1;

  const edgeCaseDuration = function(){
    throw new Error('edge case duration value');
  }

  // array helper vars;
  let lineArray;
  let NaNArray;

  // the uncertainty graphic is a charts js line graph overlaying a bar graph
  // we use a line graph with a steep drop off to create the expected task duration band
  // this logic builds out the array of values that will create this line graph
  if (_.lt(duration, 0)) {
    edgeCaseDuration()
  } else if (_.eq(duration, 0)) {
    edgeCaseDuration()
  } else if (_.eq(duration, 1)) {
    edgeCaseDuration()
  } else if (_.eq(duration, 2)) {
    lineArray = [lineHeight, 0.0];
  } else if (_.gt(duration, 2)) {

    // NaN array looks like this [NaN, NaN, NaN, .... ]
    NaNArray = _.map(_.range(1, duration - 2), () => NaN);

    lineArray = _.concat([lineHeight], NaNArray, [lineHeight, 0.0]);
  } else {
    throw new Error('task length edge case');
  }
  // There are possible edge cases here

  new Chart(canvasEl, {
    type: 'bar',
    data: {
      labels: days,
      datasets: [
        {
          type: 'line',
          label: 'Line Component',
          borderWidth: 1.0,
          pointRadius: 0.01,
          pointHoverRadius: 0.01,
          spanGaps: true,
          stepped: 'before',
          backgroundColor: lightGrey,
          fill: true,
          borderColor: darkBlack,
          data: lineArray,
        },
        {
          type: 'bar',
          data: probabilities,
          backgroundColor: barColor,
          borderColor: barBorderColor,
          borderWidth: 1.0,
        }],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        x: {
          display: true,
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            callback(value, index, values) {
              // if there are few than 10 days, show label for every day
              if (_.size(values) <= 10) {
                return value;
              }

              // if there are greater than 10 days, only show increments of 5
              if ((_.size(values) > 10) && (value % 5 === 0)) {
                return value;
              }
            },
          },
          grid: {
            display: false,
            lineWidth: 1,
          },
        },
        y: {
          display: false,
          ticks: {
            min: 0,
            max: maxDisplayProbability,
          },
          grid: {
            display: false,
          },
        },
      },
    },
  });
}
