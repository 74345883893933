<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul v-if="tasks_present" role="list" class="space-y-3" data-component="Tasks">
    <li v-for="id in task_ids()">
      <Task
        :id="id"
        :name="task_name(id)"
        :description="task_description(id)"
        :dependency_names="task_dependency_names(id)"
        :resource_names="task_resource_names(id)"
        :duration_object="task_duration_object(id)"
      ></Task>
    </li>
  </ul>
  <ul v-else role="list" class="space-y-3">
    <li>
      <NullComponent
        component_id='tasks'
        heading='Missing Tasks'
      ></NullComponent>
    </li>
  </ul>
</template>

<script>
import Task from './Task.vue'

import _ from 'lodash';
import NullComponent from '../null/NullComponent.vue';

export default {
  components: {
    Task: Task,
    NullComponent: NullComponent,
  },
  methods: {
    task_ids() {
      return _.keys(this.tasks)
    },
    task_name(id) {
      return this.tasks[id]['name']
    },
    task_description(id) {
      return this.tasks[id]['description']
    },
    task_duration_object(id) {
      return this.tasks[id]['duration']
    },
    task_dependency_names(id) {
      return _.map(this.tasks[id]['depends_on'], (d) => { return this.tasks[d]['name'] })
    },
    task_resource_names(id) {
      return _.map(this.tasks[id]['resources'], (r) => { return this.resources[r]['name'] })
    },
  },
  computed: {
    tasks_present() {
      return !_.isEmpty(this.$store.getters.tasks)
    },
    tasks() {
      return this.$store.getters.tasks
    },
    resources() {
      return this.$store.getters.resources
    }
  }
}
</script>
