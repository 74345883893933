<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="p-4" data-component="NullComponent">
    <div class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <Graphic :graphic_id=g_id></Graphic>
      <span class="mt-2 block text-sm font-semibold text-gray-900">{{heading}}</span>
    </div >
  </div>
</template>

<script>


// Components
import Graphic from '../graphics/Graphic.vue'

export default {
  props: ['component_id', 'heading'],
  components: {
    Graphic,
  },
  methods: { },
  data() {
    return {
      g_id: this.component_id
    }
  },
}
</script>
