import _ from 'lodash';
import moment from 'moment';
import on_or_before from './on-or-before.js';

export default function(i){

  // Estimated Project Span
  // Running the full simulation gives us the actual span of the project
  // that the user configures // However, there may be display features, like
  // the resource calendars that require knowing an approximate start and finish
  // date of the overall project without demanding that we run the full simulation
  // in the background jobs.
  // The purpose of this function is to return this estimated project span
  // based off of:
  // * the start date provided in the overview section of the project
  // * task estimated lengths
  // * configured resource calendars

  // user input with some of the project fields blank
  const project = _.cloneDeep(i)

  let overview, tasks, calendar;
  let schedule;

  // build an initial schedule data structure from the project
  // after we have a bootstraped schedule from the project input data
  // we will preform computations on this data object
  overview  = project['overview']
  tasks     = project['tasks']

  // remove descriptions from tasks
  _.each(_.keys(tasks), function(k){
    tasks[k] = _.omit(tasks[k], 'description')
  })

  // Compute the maximum possible project length
  // For we now we will use a heuristic that should carry us for a while
  // Consider revisiting this calculation later to put more
  // guarentees around the number to avoid edge cases.
  //
  // Assume  each tasks takes three times as long and they are all completed in serial
  // sum(t in tasks, TASK_LENGTH_MULTIPLIER * t.duration )
  //
  // This allows us build all the calendars at once up front
  const TASK_LENGTH_MULTIPLIER = 3;

  // task lengths are zero the multiplier has no effect
  // we add a buffer to ensure non-zero calendar lengths
  const ZERO_BUFFER = 5;
  let generous_task_lengths = _.map(tasks, function(task){
    return TASK_LENGTH_MULTIPLIER * task['duration']['estimate'] + ZERO_BUFFER;
  })

  // maximum possible project length
  const max_project_days = _.sum(generous_task_lengths);

  // get the start date from the project calendar
  const start_date = overview['calendar']['start']

  // compute the date that is x days away from the given date
  const date_on = function(date, days){
    const m = moment([date['year'], date['month'] - 1, date['day']]);
    m.add(days, 'days');
    return { day: m.date(), month: m.month() + 1, year: m.year() };
  }

  // compute estimated finish date
  const estimated_finish_date = date_on(start_date, max_project_days)

  const result = {
    start:  start_date,
    finish: estimated_finish_date,
    days:   max_project_days,
  }

  return _.cloneDeep(result)
}
