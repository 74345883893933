<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <p class="mt-1 text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight">Project Shark</p>
        <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">Coming Soon<span class=""><a href="/splash">!</a></span></p>
      </div>
    </div>
  </div>
</template>
