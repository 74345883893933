<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-12 bg-white">
    <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">A better way to send money.</h2>
      <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
        <div v-for="feature in features" :key="feature.name">
          <dt>
            <div class="flex items-center justify-center rounded-md">
              <img :src="feature.image" class="max-w-full h-auto" alt="..." />
            </div>
            <p class="mt-5 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
          </dt>
          <dd class="mt-2 text-base text-gray-500">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import butterfly from "../../assets/img/butterfly.svg"
import drag_text from "../../assets/img/drag-text.svg"
import uncertainty from "../../assets/img/uncertainty-modeling.svg"

const features = [
  {
    name: 'Project Simulation',
    description: 'Can you see the butterfly effects in your project schedule? Computer simulation shows how tiny schedule changes combine in unexpected ways.',
    icon: XMarkIcon,
    image: butterfly,
  },
  {
    name: 'Uncertainty Modeling',
    description: "Real world projects are filled with uncertainty. Project Shark shines a light on what you don't know.",
    icon: XMarkIcon,
    image: uncertainty,
  },
  {
    name: 'Plain Text Input',
    description: 'Are you tired of complicated GUIs slowing down your work? Build and maintain your project input as a simple text file.',
    icon: XMarkIcon,
    image: drag_text,
  },
]

export default {
  setup() {
    return {
      features,
      butterfly,
      drag_text,
      uncertainty,
    }
  },
}
</script>
