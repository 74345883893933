// Libs
import _ from 'lodash';
import moment from 'moment';

//Cal Heatmap JS
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';

// Cal Heatmap CSS
import 'cal-heatmap/cal-heatmap.css';

export default function(input) {

  const startDate = input['calendar_span']['start']

  const startDateObject = new Date(startDate['year'], startDate['month'] - 1, startDate['day']);

  const finishDate =  input['calendar_span']['finish']

  const finishDateObject = new Date(finishDate['year'], finishDate['month'] - 1, finishDate['day']);

  // calculate the total number of months to display
  const months = _.ceil(moment(finishDateObject).diff(moment(startDateObject), 'months', true));

  /*
  const cal = new CalHeatmap();

  cal.paint({
    range: 15,
    itemSelector: `#${input['selector']}`,
    domain: { type: 'year' },
    subDomain: { type: 'week' },
    verticalOrientation: false,
  });
  */

  const sameRowDayTemplate = function (DateHelper) {
    return {
      name: 'day_same_row',
      parent: 'day',
      rowsCount() {
        return 1;
      },
      columnsCount() {
        // this creates the gap
        // https://cal-heatmap.com/docs/template#columnscount
        return 31;
      },
      mapping: (startDate, endDate, defaultValues) =>
        DateHelper.intervals('day', startDate, DateHelper.date(endDate)).map(
          (d, index) => ({
            t: d,
            x: index,
            y: 0,
            ...defaultValues,
          })
        ),
      // Missing extractUnit property, will be inherit from parent
    };
  };

  // generate chart date for task days
  const chartData = _.map(input['active_days'], (obj) => {


    const day = obj['day'];
    const month = obj['month'];
    const year = obj['year'];
    const date_string = new Date(year, month - 1, day).toDateString()

    let value;
    if(input['opacity_domain'] == true){
      value = obj['opacity']
    } else {
      value = 1;
    }

    return { 'date': date_string, 'value': value };
  });

  const cal = new CalHeatmap();
  cal.addTemplates(sameRowDayTemplate);


  // ----
  cal.paint({
    data: {
      source: chartData,
      type: 'json',
      x: 'date',
      y: d => +d['value'],
      groupY: 'max',
    },
    date: { start: startDateObject },
    range: months,
    itemSelector: `#${input['heatmap_selector']}`,
    domain: {
      type: 'month',
      gutter: 5,
      label: {
        text: (input['display_months']) ? undefined : null
      },
    },
    subDomain: {
      type: 'day_same_row',
      height: 5,
      width: 5,
    },
    // docs - understand the d3 discrete color scheme
    // https://cal-heatmap.com/docs/options/scale
    // https://d3js.org/d3-scale-chromatic/sequential#schemeGreys
    scale: {
      color: {
        scheme: 'Greys',
        type: 'linear',
        domain: [0, 1],
      },
    },
  },
  [
    [
      Tooltip,
      {
        text: function (date, value, dayjsDate) {
          return dayjsDate.format('LL');
        },
      },
    ],
  ]
);
};
