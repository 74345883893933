<template>
  <div class="bg-white overflow-hidden shadow-lg rounded-lg">
    <canvas class="histogram-display"></canvas>
  </div>
</template>

<script>

// functions
import generateDurationDistribution from '../../js/lib/distributions/generate-duration-distribution.js'

// Display
import taskUncertainty from './task-uncertainty.js'

export default {
  props: ['duration', 'id'],
  methods: {
    render_uncertainty() {
      taskUncertainty( this.id, this.task_uncertainty )
    },
  },
  computed: {
    estimate() {
      return this.duration['estimate']
    },
    distribution() {
      return this.duration['distribution']
    },
    type() {
      return this.distribution['type']
    },
    early() {
      return this.distribution['parameters']['early']
    },
    late() {
      return this.distribution['parameters']['late']
    },
    std() {
      return this.distribution['parameters']['std']
    },
    task_uncertainty(){
      return {
        duration:  this.estimate,
        histogram: generateDurationDistribution( this.duration )
      }
    },
  },
  watch: {
    estimate: function() {
      return this.render_uncertainty()
    },
    type: function() {
      return this.render_uncertainty()
    },
    early() {
      return this.render_uncertainty()
    },
    late() {
      return this.render_uncertainty()
    },
    std() {
      return this.render_uncertainty()
    },
  },
  mounted: function() {
    return this.render_uncertainty()
  },
}
</script>
