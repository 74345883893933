<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-bind:id="id"></div>
</template>


<script>

// Libraries
import _ from 'lodash';
import Promise from 'bluebird'

// Functions
import generateCalendar from '../../js/lib/calendars/generate-calendar.js'

// Display
import calendarDisplay from './calendar.js'

const displayCalendar = function (input) {
  return new Promise((resolve) => {
    resolve(calendarDisplay(input['calendar'], input['selector']));
  });
};

export default {
  props: ['id','start', 'finish', 'include', 'exclude'],
  methods: {
    render_calendar(){
      return Promise.resolve(this.display_input()).then(displayCalendar);
    },
    generate_calendar(){

      const i = {
        start:        this.start,
        finish:       this.finish,
        exclude:      this.exclude,
        include:      this.include,
      }

      // generate the calendar objects
      const days = generateCalendar(i)

      // return project days
      return days
    },
    display_input(){
      return {calendar: this.generate_calendar(), selector: `#${this.id}`}
    },
  },
  updated: function() {
    return this.render_calendar()
  },
  mounted: function() {
    return this.render_calendar()
  },
}
</script>
